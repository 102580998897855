import { useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';

import {
  batchBankSlipMailing,
  BatchBankSlipMailingType,
} from 'services/batchBankSlipMailing';

export type OnlineBatchRequestType = {
  code: string;
  date: string;
  status: string;
  context: BatchBankSlipMailingType;
};
export const useListBatchBankSlipMailing = ({
  code,
  date,
  status,
  context,
}: OnlineBatchRequestType) => {
  const {
    data: allBatches,
    error,
    ...rest
  } = useInfiniteQuery(
    ['online-batches'],
    (page) =>
      batchBankSlipMailing(context).list({ code, date, status, ...page }),
    {
      getNextPageParam: (data) => {
        if (data.items.length === 0) return undefined;
        return data.meta.currentPage + 1;
      },
    }
  );

  const allValues = useMemo(
    () => allBatches?.pages.flatMap(({ items }) => items),
    [allBatches]
  );
  return {
    allBatches: allValues?.length ? allValues : [],
    rest,
  };
};
