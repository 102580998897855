import {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useReducer,
} from 'react';

import AlertDialog from 'components/shared/core/alertDialog';
import { AlertType as DefaultAlertType } from 'components/shared/core/confirmDialog';

export type AlertType = 'loading' | DefaultAlertType;

export type ShowAlertPayload = {
  type: AlertType;
  message?: string;
  title: string;
  sizeMessage?: string;
  delay?: number;
};

interface AlertContextProps {
  isOpen: boolean;
  title: string;
  message?: string;
  type?: AlertType;
  sizeMessage?: string;
  delay?: number;
  showAlert: (data: ShowAlertPayload) => void;
  setVisible: (value: boolean) => void;
  setClear: () => void;
}

interface AlertState {
  isOpen: boolean;
  title: string;
  message?: string;
  type?: AlertType;
}

type AlertTypes =
  | {
      type: 'SHOW_ALERT';
      payload: { type: AlertType; message?: string; title: string };
    }
  | {
      type: 'SET_VISIBLE';
      payload: boolean;
    }
  | {
      type: 'SET_CLEAR';
    };

const alertReducer = (state: AlertState, action: AlertTypes): AlertState => {
  switch (action.type) {
    case 'SHOW_ALERT':
      return {
        ...state,
        isOpen: true,
        ...action.payload,
      };
    case 'SET_VISIBLE':
      return {
        ...state,
        isOpen: action.payload,
      };
    case 'SET_CLEAR':
      return {
        isOpen: false,
        title: '',
        message: undefined,
      };
    default:
      return state;
  }
};

export const AlertContext = createContext<AlertContextProps>({
  isOpen: false,
  message: '',
  title: '',
  showAlert: () => {},
  setVisible: () => {},
  setClear: () => {},
});

interface AlertProviderProps {
  children?: ReactNode;
}

export const AlertProvider = ({ children }: AlertProviderProps) => {
  const [state, dispatch] = useReducer(alertReducer, {
    isOpen: false,
    message: '',
    title: '',
  });

  const showAlert = (payload: ShowAlertPayload) =>
    dispatch({ type: 'SHOW_ALERT', payload });

  const setVisible = (payload: boolean) =>
    dispatch({ type: 'SET_VISIBLE', payload });

  const setClear = () => dispatch({ type: 'SET_CLEAR' });

  const contextValue = useMemo(
    () => ({
      ...state,
      showAlert,
      setVisible,
      setClear,
    }),
    [state, showAlert, setVisible, setClear]
  );

  return (
    <AlertContext.Provider value={contextValue}>
      {children}
      <AlertDialog delay={5000} />
    </AlertContext.Provider>
  );
};

export const useAlert = () => useContext(AlertContext);
