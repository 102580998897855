import { useIntl } from 'react-intl';

import CreateNewOnlineBatchReadjustments from 'components/contextual/createOnlinePaymentSlip';
import HomeContent from 'components/contextual/homeContent';
import Divider from 'components/shared/core/divider';
import Breadcrumb from 'components/shared/newCore/Breadcrumb';

import { useBreadcrumbLinks } from './hooks';
import * as S from './styles';

const PlanBatchBankSlipMailingForm = () => {
  const intl = useIntl();
  const breadcumbLinks = useBreadcrumbLinks();

  return (
    <HomeContent>
      <Breadcrumb path={breadcumbLinks} />
      <S.Header>
        <S.Title>
          {intl.formatMessage({
            id: 'createOnlinePaymentSlip.newOnlinePaymentSlip',
          })}
        </S.Title>
      </S.Header>
      <Divider />
      <CreateNewOnlineBatchReadjustments context="plans" />
    </HomeContent>
  );
};

export default PlanBatchBankSlipMailingForm;
