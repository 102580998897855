import { AxiosError } from 'axios';
import { useAlert } from 'contexts';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useMutation, useQuery } from 'react-query';
import { ReportInformation } from 'types/BillingScheduling';
import { downloadArchive } from 'utils-general/downloadArchive';

import { getComissionReport, postComissionReport } from 'services/billings';

import { ApiErrors } from 'constants/apiErrors';

export const useFetchComissionReportFile = (
  fileName: string,
  shouldRefetch = true
) =>
  useQuery(['comissionReport', fileName], () => getComissionReport(fileName), {
    enabled: Boolean(fileName),
    refetchInterval: shouldRefetch && 5000,
  });

export const useReportComission = () => {
  const intl = useIntl();
  const { showAlert, setClear } = useAlert();
  const [fileName, setFileName] = useState('');
  const [shouldRefetch, setShouldRefetch] = useState(true);
  const { data, error } = useFetchComissionReportFile(fileName, shouldRefetch);

  useEffect(() => {
    if (error) {
      const axiosError: AxiosError = error as AxiosError;
      const status = axiosError?.response?.status;
      setShouldRefetch(!status || status === 404);
    }
  }, [error]);

  useEffect(() => {
    if (data?.processingStatus === 'error') {
      setClear();
      process.nextTick(() => {
        showAlert({
          title: intl.formatMessage({ id: 'report' }),
          type: 'error',
        });
      });
      setFileName('');
      setShouldRefetch(false);
      return;
    }

    // arquivo gerado com sucesso.
    if (data?.processingStatus === 'done') {
      setClear();
      process.nextTick(() => {
        showAlert({
          title: intl.formatMessage({
            id: 'report.generated',
          }),
          type: 'success',
          message: intl.formatMessage({
            id: 'electronicBillingReport.timeToGenerateReport',
          }),
        });
      });
      downloadArchive(data?.url);
      setShouldRefetch(false);
    }
  }, [data]);

  return useMutation((body: ReportInformation) => postComissionReport(body), {
    onMutate: () => {
      showAlert({
        title: intl.formatMessage({
          id: 'electronicBillingReport.generateReport',
        }),
        type: 'loading',
        message: intl.formatMessage({
          id: 'electronicBillingReport.timeToGenerateReport',
        }),
      });
    },
    onSuccess: ({ fileName }) => {
      setFileName(fileName);
      setShouldRefetch(true);
    },
    onError: (error) => {
      const { response: errorResponse } = error as AxiosError;

      if (errorResponse?.data.error === ApiErrors.IncompleteUserRequester) {
        setClear();
        showAlert({
          title: intl.formatMessage({
            id: 'electronicBillingReport.errorDialog.generateReportTitle',
          }),
          message: intl.formatMessage({
            id: 'electronicBillingReport.errorDialog.generateReportErrorUserNotHaveGsAdminId',
          }),
          type: 'error',
          delay: 6_000,
        });
        return;
      }
      setClear();
      showAlert({
        title: intl.formatMessage({
          id: 'electronicBillingReport.errorDialog.generateReportTitle',
        }),
        type: 'error',
      });
    },
  });
};
