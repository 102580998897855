import { useIntl } from 'react-intl';
import * as Yup from 'yup';

export const useCreateOnlinePaymentSlipValidation = () => {
  const intl = useIntl();

  return Yup.object().shape({
    archive: Yup.mixed()
      .required(
        intl.formatMessage({
          id: 'createPaymentSlip.form.emptyArchive',
        })
      )
      .test(
        'is-valid-file',
        intl.formatMessage({ id: 'invalidFile' }),
        function (value) {
          const path = this.path;

          if (!value) return false;

          const allowedExtensions = ['xlsx'];
          const fileExtension = value.name.split('.').pop();
          return allowedExtensions.includes(fileExtension)
            ? true
            : this.createError({
                message: intl.formatMessage({ id: 'invalidFile' }),
                path,
              });
        }
      ),
    dateStart: Yup.date().required(
      intl.formatMessage({ id: 'createPaymentSlip.form.dateError' })
    ),
    dateEnd: Yup.date()
      .required(intl.formatMessage({ id: 'createPaymentSlip.form.dateError' }))
      .test(
        'is-greater-than-start-date',
        intl.formatMessage({ id: 'createPaymentSlip.form.endDateError' }),
        function (value) {
          const { dateStart } = this.parent;
          return value! > dateStart;
        }
      ),
  });
};
