import { ButtonHTMLAttributes } from 'react';

import * as S from './ButtonLink.styles';

export type ButtonVariant = 'lighter' | 'darken';
export interface ButtonLinkProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
}

const ButtonLink = ({
  children,
  variant,
  type = 'button',
  ...props
}: ButtonLinkProps) => (
  <S.Button className={variant} type={type} {...props}>
    {children}
  </S.Button>
);

export default ButtonLink;
