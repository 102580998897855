import styled, { css } from 'styled-components';

import Paper from 'components/shared/core/paper';
import CustomText from 'components/shared/core/text';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: ${({ theme }) => theme.spacing.md};
`;

export const FlexGrow = styled(Container)`
  gap: 9px;
  flex-grow: 1;
`;

export const Nav = styled.nav<{ padding?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  & header {
    display: flex;
    align-items: center;

    ${({ padding }) =>
      padding &&
      css`
        padding-left: 11rem;
        padding-left: calc((100% - (3 * 350px)) / 2);

        @media screen and (max-width: 830px) {
          padding-left: calc((100% - 350px) / 2);
        }

        @media screen and (max-width: 375px) {
          padding-left: calc((100% - 86vw) / 2);
        }
      `}
  }
`;

export const Icon = styled.img`
  width: 51px;
  height: 44px;
  margin-right: 16px;
`;

export const Grid = styled.section`
  display: grid;
  grid-template-columns: repeat(3, 338px);
  grid-auto-rows: 180px;
  grid-gap: ${({ theme }) => theme.spacing.sm1};
  justify-content: center;
  padding-block: 3vh;

  @media screen and (max-width: 830px) {
    gap: ${({ theme }) => theme.spacing.sm1};
    display: grid;
    grid-template-columns: repeat(1, 338px);
  }

  @media screen and (max-width: 375px) {
    gap: ${({ theme }) => theme.spacing.sm1};
    display: grid;
    grid-template-columns: repeat(1, 86vw);
    justify-content: center;
  }
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.large};
    color: ${theme.texts.lightSecondary};
  `}
  font-weight: 600;
  line-height: 32px;
  margin-right: ${({ theme }) => theme.spacing.md};
  padding-block-end: 20px;
  writing-mode: horizontal-tb;
  align-self: start;
`;

export const Text = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.medium};
    color: ${theme.texts.lightSecondary2};
  `}
  font-weight: 600;
  text-transform: uppercase;
`;

export const Link = styled(CustomText)`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.small};
    color: ${theme.texts.darkSecondary};
  `}
  font-weight: 600;
  letter-spacing: 0.1em;
`;

export const CustomPaper = styled(Paper)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  height: 11.6rem;
  gap: ${({ theme }) => theme.spacing.xs1};
  & svg {
    width: 48px;
    height: 48px;
  }
`;

export const SelectApprover = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
`;

export const Footer = styled.footer`
  ${({ theme }) => css`
    display: flex;
    border-top: 1px solid ${theme.separatorColorLight};
    padding-top: ${theme.spacing.sm1};
    justify-content: end;
    width: 100%;
  `}
`;
