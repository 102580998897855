import { AxiosError, AxiosResponse } from 'axios';
import { chunk } from 'lodash';

import api from 'services/api';

import { getURLWithParams } from 'lib/contextual';
import { formatDateWithTimezone } from 'lib/core/DateConverter';

import { DELIVERY_MANAGER } from 'constants/endpoints';

import {
  CollectorDeliveriesType,
  DeliveryItem,
  DeliveryOneResponse,
  DerivedAssociate,
  getPaginatedDeliveriesProps,
  PaginationMetaInfo,
  UpdateDeliveryMetaInfo,
} from './types';

export const getPaginatedCollectors = async ({
  pageParam = 1,
  limit = 100,
  order = 'name',
  filterName = '',
}): Promise<{
  meta: PaginationMetaInfo;
  data: Array<{
    code: string;
    createdAt: string;
    name: string;
    updatedAt: string;
  }>;
}> => {
  const { data } = await api.getRequest(
    getURLWithParams(DELIVERY_MANAGER.COLLECTORS_PAGINATED, {
      page: pageParam,
      limit,
      order,
      ...(filterName !== '' && filterName.length >= 3
        ? { name: filterName }
        : undefined),
    })
  );
  return data;
};

export const getCollectorDeliveries = async ({
  collectorCode,
  deliveryStatus,
  startDate,
  endDate,
}: {
  collectorCode: string;
  deliveryStatus?: string[];
  startDate?: string;
  endDate?: string;
}): Promise<Array<CollectorDeliveriesType>> => {
  const object = getParams({
    startDate,
    endDate,
    status:
      deliveryStatus?.length === 3 ? undefined : deliveryStatus?.join(','),
  });
  const { data } = await api.getRequest(
    getURLWithParams(DELIVERY_MANAGER.COLLECTORS_ORDERS(collectorCode), {
      ...(Object.keys(object).length > 0 ? { ...object } : undefined),
    })
  );
  return data;
};

const getParams = (obj: Record<string, unknown>) => {
  const params: Record<string, unknown> = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] && obj[key] !== '') {
      params[key] = obj[key];
    }
  });
  return params;
};

export const getPaginatedAssociate = async ({
  pageParam = 1,
  limit = 300,
  order = 'name',
  filterName = '',
  cpf = '',
  rg = '',
  cnpj = '',
  personType = '',
  batch = '',
  group = '',
  subgroup = '',
}): Promise<{
  meta: PaginationMetaInfo;
  data: Array<DerivedAssociate>;
}> => {
  const object = getParams({
    cpf,
    rg,
    cnpj,
    personType,
    batch,
    group,
    subgroup,
  });

  const fullParams = {
    page: pageParam,
    limit,
    order,
    ...(filterName !== '' ? { name: filterName } : undefined),
    ...(Object.keys(object).length > 0 ? { ...object } : undefined),
  };
  const { data } = await api.getRequest(
    getURLWithParams(DELIVERY_MANAGER.ASSOCIATES_PAGINATED, fullParams)
  );
  return data;
};

export const getPaginatedDeliveryTypes = async (): Promise<{
  meta: PaginationMetaInfo;
  data: Array<{ id: number; description: string }>;
}> => {
  const { data } = await api.getRequest(DELIVERY_MANAGER.DELIVERIES_TYPES);
  return data;
};

export const getAssociateById = async (
  subscription: string
): Promise<DerivedAssociate> => {
  const { data } = await api.getRequest(
    DELIVERY_MANAGER.ASSOCIATES_ONE(subscription)
  );
  return data;
};
export const createDelivery = ({
  collector,
  deliveryDate,
  deliveryType,
}: {
  collector: string;
  deliveryDate: string;
  deliveryType: string;
}) => {
  const obj = {
    collectorCode: collector,
    deliveryTypeId: Number(deliveryType),
    deliveryDate: new Date(deliveryDate).toISOString(),
  };

  return api.postRequest(DELIVERY_MANAGER.DELIVERIES_ALL, obj);
};

export const putUpdateDelivery = ({
  id,
  collector,
  deliveryDate,
  deliveryType,
}: {
  id: string;
  collector: string;
  deliveryDate: string;
  deliveryType: string;
}) => {
  const obj = {
    collectorCode: collector,
    deliveryTypeId: Number(deliveryType),
    deliveryDate: new Date(deliveryDate).toISOString(),
  };

  return api.putRequest(DELIVERY_MANAGER.DELIVERIES_ONE(id), obj);
};

export const getPaginatedDeliveries = async ({
  pageParam = 1,
  limit = 10,
  startDate,
  endDate,
  order = '-id',
}: getPaginatedDeliveriesProps): Promise<{
  meta: PaginationMetaInfo;
  data: Array<DeliveryOneResponse>;
}> => {
  const { data } = await api.getRequest(
    getURLWithParams(DELIVERY_MANAGER.DELIVERIES_ALL, {
      page: pageParam,
      limit,
      order,
      startDate,
      endDate,
    })
  );
  return data;
};

export const getDeliveryById = async (
  id: string
): Promise<Array<DeliveryOneResponse>> => {
  const { data } = await api.getRequest(DELIVERY_MANAGER.DELIVERIES_ONE(id));
  if (data) return [data];
  return [];
};

export const getDeliveryItemsById = async ({
  id,
  pageParam,
  limit,
}: {
  id: string;
  pageParam?: number;
  limit?: number;
}): Promise<{
  meta: PaginationMetaInfo;
  data: Array<DeliveryItem>;
}> => {
  const { data } = await api.getRequest(
    getURLWithParams(DELIVERY_MANAGER.DELIVERIES_ITEMS_BY_ID(id), {
      page: pageParam,
      limit,
    })
  );
  return data;
};

export type PatchDeliveryItemsResponse = {
  associate: DerivedAssociate;
  comment: string;
  createdAt: string;
  delivery: {
    collector: {
      code: string;
      name: string;
      status: string;
    };
    createdAt: string;
    date: string;
    id: number;
    type: {
      description: string;
      id: number;
    };
  };
  phone: string;
  receivedBy: string;
  reference: number;
  rg: string;
  status: string;
  statusDate: string;
  type: string;
  updatedAt: string;
};

export const patchDeliveryItems = async ({
  deliveryId,
  subscriptionId,
  itemData,
}: {
  deliveryId: number;
  subscriptionId: number;
  itemData: {
    status: string;
    rg: string;
    phone: string;
    receivedBy: string;
    comment: string;
    statusDate?: string;
  };
}): Promise<AxiosResponse<PatchDeliveryItemsResponse>> => {
  const dataWithStatusDate = {
    ...itemData,
    ...(itemData.statusDate && {
      statusDate: formatDateWithTimezone(itemData.statusDate),
    }),
  };
  return await api.patchRequest(
    DELIVERY_MANAGER.DELIVERIES_ITEMS_CREATE_BY_ID_AND_SUBSCRIPTION(
      deliveryId.toString(),
      subscriptionId.toString()
    ),
    dataWithStatusDate
  );
};

export const patchArrayOfDeliveryItems = async (
  deliveryItemArray: Array<{
    deliveryId: number;
    subscriptionId: number;
    itemData: {
      status: string;
      rg: string;
      phone: string;
      receivedBy: string;
      comment: string;
      statusDate?: string;
    };
  }>
) => {
  const arrayChunks = chunk(deliveryItemArray, 5);

  const finalResponse: PromiseSettledResult<PatchDeliveryItemsResponse>[] = [];
  for await (const chunks of arrayChunks) {
    const result = await Promise.allSettled(
      chunks.map(async (values) => {
        try {
          const { data } = await api.patchRequest(
            DELIVERY_MANAGER.DELIVERIES_ITEMS_CREATE_BY_ID_AND_SUBSCRIPTION(
              values.deliveryId.toString(),
              values.subscriptionId.toString()
            ),
            {
              ...values.itemData,
              statusDate: values.itemData.statusDate
                ? formatDateWithTimezone(values.itemData.statusDate)
                : undefined,
            }
          );
          return data as PatchDeliveryItemsResponse;
        } catch (err) {
          throw err;
        }
      })
    );
    finalResponse.push(...result);
  }

  return finalResponse;
};

export const patchDeliverySubscriptions = ({
  deliveryId,
  arraySubscriptions,
}: {
  deliveryId: string;
  arraySubscriptions: Array<string>;
}): Promise<
  | AxiosResponse<{
      meta: UpdateDeliveryMetaInfo;
      data: Array<DeliveryItem>;
    }>
  | AxiosError
> =>
  api.patchRequest(
    DELIVERY_MANAGER.DELIVERIES_ITEMS_BY_ID(deliveryId),
    arraySubscriptions.map((subscription) => Number(subscription))
  );
