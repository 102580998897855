import { useAlert } from 'contexts';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { CreateOnlinePaymentSlipForm } from 'types/PaymentSlip';

import { BatchBankSlipMailingType } from 'services/batchBankSlipMailing';

import { useCreateBatchBankSlipMailing } from 'useCases/onlinePaymentSlip';

import Divider from 'components/shared/core/divider';
import { Grid, Row } from 'components/shared/core/grid';
import RoundedIcon from 'components/shared/core/roundedIcon';
import ButtonLink from 'components/shared/forms/ButtonLink';
import Input from 'components/shared/forms/Input';

import { Urls } from 'constants/urls';

import { ReactComponent as ArrowRight } from 'assets/images/ArrowRight.svg';

import * as S from './CreateNewOnlineBatchReadjustments.styles';
import { useCreateOnlinePaymentSlipValidation } from './formValidation';
import { getInitialValues, mapToApi } from './mappers';

interface CreateNewOnlineBatchReadjustmentsProps {
  context: BatchBankSlipMailingType;
}

const CreateNewOnlineBatchReadjustments = ({
  context,
}: CreateNewOnlineBatchReadjustmentsProps) => {
  const history = useNavigate();
  const intl = useIntl();
  const { showAlert, setClear } = useAlert();
  const { mutate } = useCreateBatchBankSlipMailing(context);

  const homePage =
    context === 'plans' ? Urls.onlinePaymentSlip : Urls.cemeteryBankSlipsBatch;

  const formik = useFormik<CreateOnlinePaymentSlipForm>({
    initialValues: getInitialValues(),
    validationSchema: useCreateOnlinePaymentSlipValidation(),
    onSubmit: () => {
      showAlert({
        type: 'loading',
        title: intl.formatMessage({
          id: 'createOnlinePaymentSlip.form.saveBatch',
        }),
      });
      mutate(
        {
          batch: mapToApi(formik.values),
          archive: formik.values.archive!,
        },
        {
          onSuccess() {
            setClear();
            setTimeout(() => {
              showAlert({
                type: 'success',
                title: intl.formatMessage({
                  id: 'createOnlinePaymentSlip.form.submitEditSuccess',
                }),
              });
            });

            history(homePage);
          },
        }
      );
    },
  });

  const handleFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      formik.setFieldValue('archive', file);
    }
  };

  return (
    <S.Form>
      <Grid>
        <Row span="4">
          <Input
            onChange={(e) => handleFile(e)}
            variant="lighter"
            required
            id="inputFileReadjustments"
            type="file"
            accept=".xlsx"
            inputFileLabel={intl.formatMessage({
              id: 'createPaymentSlip.form.label.buttonFile',
            })}
            label={intl.formatMessage({
              id: 'createPaymentSlip.form.label.file',
            })}
            hasErrors={formik.touched.archive && Boolean(formik.errors.archive)}
            helperText={formik.errors.archive}
          />
        </Row>
        <Row span="4">
          <Input
            required
            id="dateStartReadjustments"
            name="dateStart"
            value={formik.values.dateStart}
            hasErrors={
              formik.touched.dateStart && Boolean(formik.errors.dateStart)
            }
            helperText={formik.errors.dateStart}
            onChange={formik.handleChange}
            variant="lighter"
            type="date"
            label={intl.formatMessage({
              id: 'createPaymentSlip.form.label.initialDate',
            })}
          />
        </Row>
        <Row span="4">
          <Input
            required
            id="dateEndReadjustments"
            name="dateEnd"
            type="date"
            value={formik.values.dateEnd}
            hasErrors={formik.touched.dateEnd && Boolean(formik.errors.dateEnd)}
            helperText={formik.errors.dateEnd}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            variant="lighter"
            label={intl.formatMessage({
              id: 'createPaymentSlip.form.label.finalDate',
            })}
          />
        </Row>
      </Grid>
      <Divider />
      <S.Footer>
        <ButtonLink
          onClick={() => formik.handleSubmit()}
          type="button"
          variant="lighter"
        >
          {intl.formatMessage({
            id: 'createOnlinePaymentSlip.form.buttonSubmit',
          })}
          <RoundedIcon>
            <ArrowRight />
          </RoundedIcon>
        </ButtonLink>
      </S.Footer>
    </S.Form>
  );
};

export default CreateNewOnlineBatchReadjustments;
