import { useSession } from 'contexts';
import { addDays } from 'date-fns';
import { useFormik } from 'formik';
import ComponentGate from 'permissions/ComponentGate';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { useReportComission } from 'useCases/billings/useReportComission';

import { formatFromMask } from 'lib/core/DateConverter';

import SelectUsers from 'components/contextual/SelectUser';
import { CollectionCommissionTable } from 'components/contextual/collectionCommissionTable';
import HomeContent from 'components/contextual/homeContent';
import { BreadcrumbLink } from 'components/shared';
import AlertDialog from 'components/shared/core/alertDialog';
import Divider from 'components/shared/core/divider';
import RoundedIcon from 'components/shared/core/roundedIcon';
import ButtonLink from 'components/shared/forms/ButtonLink';
import CustomSelect from 'components/shared/forms/CustomSelect';
import Input from 'components/shared/forms/Input';
import RadioInputGroup from 'components/shared/forms/RadioInputGroup';
import Breadcrumb from 'components/shared/newCore/Breadcrumb';
import Tooltip from 'components/shared/newCore/Tooltip';
import Button from 'components/shared/newForms/Button';

import { ComponentsPermissions } from 'constants/enums';
import { Urls } from 'constants/urls';

import { ReactComponent as FaArrowRight } from 'assets/images/ArrowRight.svg';

import * as S from './collectionCommissionReport.styles';
import { dataColectionTable } from './collectionMockTable';
import { FileOptions, ReportTemplate, ReportType } from './types';
import { useCollectionCommissionReportValidation } from './useCollectionCommissionReportValidation';

export interface dataColectionTableInterface {
  id: number;
  startDate: string;
  endDate: string;
  campaign: string;
  receptive: string;
}

const allowedCollectorFilterGroups = ['master', 'gestor equipe cobranca'];

const enum RecurrenceValue {
  None = 'none',
  Include = 'include',
  Only = 'only',
}

const CollectionCommissionReport = () => {
  const intl = useIntl();
  const [showCommissionTable, setShowCommissionTable] = useState(false);
  const { mutate } = useReportComission();
  const { user } = useSession();
  const { roles } = user;
  const allowCollectorFilter = allowedCollectorFilterGroups.some((group) =>
    roles.map((r) => r.name).includes(group)
  );

  const breadcrumbLinks: BreadcrumbLink[] = useMemo(
    () => [
      {
        label: intl.formatMessage({ id: 'breadcrumb.homePage' }),
        redirectTo: Urls.HomePage,
      },
      {
        label: intl.formatMessage({ id: 'breadcrumb.chargeAssociate' }),
        redirectTo: Urls.associateChargeMenu,
      },
      {
        label: intl.formatMessage({ id: 'breadcrumb.reportAndResearchMenu' }),
        redirectTo: Urls.reportAndResearchMenu,
      },
      {
        label: intl.formatMessage({
          id: 'breadcrumb.collectionCommissionReport',
        }),
        redirectTo: Urls.currentCampaign,
      },
    ],
    [intl]
  );

  const validationSchema = useCollectionCommissionReportValidation();

  const reportType = [
    {
      label: intl.formatMessage({
        id: 'collectionCommissionReport.reportType.campaign',
      }),
      value: ReportType.Campaign,
    },
    {
      label: intl.formatMessage({
        id: 'collectionCommissionReport.reportType.receptive',
      }),
      value: ReportType.Receptive,
    },
  ];

  const reportTemplate = [
    {
      label: intl.formatMessage({
        id: 'collectionCommissionReport.reportTemplate.analytical',
      }),
      value: ReportTemplate.Analytical,
    },
    {
      label: intl.formatMessage({
        id: 'collectionCommissionReport.reportTemplate.synthetic',
      }),
      value: ReportTemplate.Synthetic,
    },
  ];

  const file = [
    {
      label: intl.formatMessage({
        id: 'collectionCommissionReport.fileOptions.PDF.label',
      }),
      value: FileOptions.PDF,
    },
    {
      label: intl.formatMessage({
        id: 'collectionCommissionReport.fileOptions.EXCEL.label',
      }),
      value: FileOptions.EXCEL,
    },
    {
      label: intl.formatMessage({
        id: 'collectionCommissionReport.fileOptions.LIBREOFFICE.label',
      }),
      value: FileOptions.LIBREOFFICE,
    },
  ];

  const recurrenceOptions = [
    {
      label: intl.formatMessage({
        id: 'collectionCommissionReport.recurrenceOptions.none',
      }),
      value: RecurrenceValue.None,
    },
    {
      label: intl.formatMessage({
        id: 'collectionCommissionReport.recurrenceOptions.include',
      }),
      value: RecurrenceValue.Include,
    },
    {
      label: intl.formatMessage({
        id: 'collectionCommissionReport.recurrenceOptions.only',
      }),
      value: RecurrenceValue.Only,
    },
  ];

  const formik = useFormik({
    initialValues: {
      startDate: formatFromMask(addDays(new Date(), -7), 'yyyy-MM-dd'),
      endDate: formatFromMask(new Date(), 'yyyy-MM-dd'),
      file: '',
      selectCollector: '',
      reportType: reportType[0].value,
      reportTemplate: reportTemplate[0].value,
      recurrenceOption: RecurrenceValue.None,
    },
    validationSchema,
    onSubmit: (values) => {
      const recurrenceConfig = {
        includeRecurrent: values.recurrenceOption === RecurrenceValue.Include,
        onlyRecurrent: values.recurrenceOption === RecurrenceValue.Only,
      };

      mutate({
        initialDate: values.startDate,
        finalDate: values.endDate,
        fileType: values.file,
        reportType: values.reportType,
        reportTemplate: values.reportTemplate,
        userCode: Number(values.selectCollector),
        ...recurrenceConfig,
        forceRetry: false,
      });
    },
  });

  const handleShowCommissionTable = () => {
    setShowCommissionTable(true);
  };
  const handleCloseCommissionTable = () => {
    setShowCommissionTable(false);
  };
  return (
    <HomeContent>
      <Breadcrumb path={breadcrumbLinks} />
      <S.Header>
        <S.Title level={1}>
          {intl.formatMessage({
            id: 'collectionCommissionReport.home.title',
          })}
        </S.Title>
        <S.ButtonStyle>
          <Button variants="outlined" onClick={handleShowCommissionTable}>
            {intl.formatMessage({
              id: 'collectionCommissionReport.seeCommissionTable',
            })}
          </Button>
        </S.ButtonStyle>
      </S.Header>
      <form onSubmit={formik.handleSubmit}>
        <S.QueryWrapper>
          <S.InputWrapper>
            <Input
              required
              id="startDate"
              name="startDate"
              onChange={formik.handleChange}
              value={formik.values?.startDate}
              variant="lighter"
              type="date"
              label={intl.formatMessage({
                id: 'collectionCommissionReport.initialPaymentDate',
              })}
              hasErrors={
                !formik.isValid && formik.errors.startDate !== undefined
              }
              helperText={String(formik.errors.startDate ?? '')}
            />
          </S.InputWrapper>
          <S.InputWrapper>
            <Input
              required
              id="endDate"
              name="endDate"
              onChange={formik.handleChange}
              value={formik.values?.endDate}
              variant="lighter"
              type="date"
              label={intl.formatMessage({
                id: 'collectionCommissionReport.finalPaymentDate',
              })}
              helperText={String(formik.errors.endDate ?? '')}
              hasErrors={!formik.isValid && formik.errors.endDate !== undefined}
            />
          </S.InputWrapper>
          <ComponentGate
            permission={ComponentsPermissions.COMISSION_REPORT_USER_FILTER}
          >
            <S.InputWrapper>
              <SelectUsers
                allowCollectorFilter={allowCollectorFilter}
                fieldValue={formik.values.selectCollector}
                setFieldValue={(value) =>
                  formik.setFieldValue('selectCollector', value)
                }
              />
            </S.InputWrapper>
          </ComponentGate>

          <S.InputWrapper>
            <CustomSelect
              id="recurrenceOption"
              inputName="recurrenceOption"
              label={intl.formatMessage({
                id: 'collectionCommissionReport.recurrence',
              })}
              inputValue={formik.values.recurrenceOption}
              setValue={formik.setFieldValue}
              options={recurrenceOptions}
            />
          </S.InputWrapper>
        </S.QueryWrapper>
        <S.QueryWrapper>
          <S.InputWrapper>
            <S.LabelSpan htmlFor={'reportType'}>
              {intl.formatMessage({
                id: 'collectionCommissionReport.labelTipoRelatorio',
              })}
            </S.LabelSpan>
            <S.RadioButtomWrapper>
              <RadioInputGroup
                selected={{ value: formik.values.reportType, label: '' }}
                options={reportType}
                onChange={(e) => {
                  formik.setFieldValue('reportType', e.value);
                }}
                name="reportType"
              />
            </S.RadioButtomWrapper>
            {Boolean(formik.errors.reportType) && (
              <Tooltip
                type="error"
                message={formik.errors.reportType as string}
              />
            )}
          </S.InputWrapper>
          <S.InputWrapper>
            <S.LabelSpan htmlFor={'reportTemplate'}>
              {intl.formatMessage({
                id: 'collectionCommissionReport.labelModeloRelatorio',
              })}
            </S.LabelSpan>
            <S.RadioButtomWrapper>
              <RadioInputGroup
                selected={{ value: formik.values.reportTemplate, label: '' }}
                options={reportTemplate}
                onChange={(e) => {
                  formik.setFieldValue('reportTemplate', e.value);
                }}
                name="reportTemplate"
              />
            </S.RadioButtomWrapper>
            {Boolean(formik.errors.reportTemplate) && (
              <Tooltip
                type="error"
                message={formik.errors.reportTemplate as string}
              />
            )}
          </S.InputWrapper>
          <S.InputWrapper>
            <CustomSelect
              inputName="file"
              id="file"
              label={intl.formatMessage({
                id: 'collectionCommissionReport.file',
              })}
              required
              inputValue={formik.values.file}
              setValue={formik.setFieldValue}
              options={file}
              hasErrors={formik.touched.file && Boolean(formik.errors.file)}
              helperText={String(formik.errors.file)}
            />
          </S.InputWrapper>
        </S.QueryWrapper>
        <Divider />
        <S.ButtonContainer>
          <ButtonLink type="submit">
            {intl.formatMessage({
              id: 'collectionCommissionReport.downloadReport',
            })}
            <RoundedIcon>
              <FaArrowRight />
            </RoundedIcon>
          </ButtonLink>
        </S.ButtonContainer>
        <CollectionCommissionTable
          open={showCommissionTable}
          closeModal={handleCloseCommissionTable}
          isClosing={!showCommissionTable}
          dataColectionTable={dataColectionTable}
        />
      </form>
      <AlertDialog delay={2000} />
    </HomeContent>
  );
};

export default CollectionCommissionReport;
