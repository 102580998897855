import axios, { AxiosResponse } from 'axios';
import { BatchBankSlipMailing } from 'types/BatchBankSlipMailing';
import { BatchInfo } from 'types/BatchInfo';
import { BatchResponse } from 'types/BatchResponse';
import { useCreateBatchBankSlipMailingRequest } from 'types/PaymentSlip';

import { getURLWithParams } from 'lib/contextual';

import { BANK_SLIP } from 'constants/endpoints';

import api from './api';

export type BatchBankSlipMailingType = 'plans' | 'cemeteries';
export const batchBankSlipMailing = (context: BatchBankSlipMailingType) => {
  const resourceUrl =
    context === 'plans'
      ? BANK_SLIP.PLAN_BATCH_BANK_SLIP_MAILINGS
      : BANK_SLIP.CEMETERIES_BATCH_BANK_SLIP_MAILINGS;
  const entityUrl =
    context === 'plans'
      ? BANK_SLIP.PLAN_BATCH_BANK_SLIP_MAILING_EXECUTE
      : BANK_SLIP.CEMETERIES_BATCH_BANK_SLIP_MAILING_EXECUTE;

  const list = async ({
    pageParam = 1,
    code = '',
    date = '',
    status = '',
  }): Promise<{
    items: BatchBankSlipMailing[];
    meta: { currentPage: number };
  }> => {
    const params = {
      page: pageParam,
      limit: 15,
      ...(code !== '' ? { code } : ''),
      ...(date !== '' ? { createdAt: date } : ''),
      ...(status !== '' ? { status } : ''),
    };

    const url = getURLWithParams(resourceUrl, params);

    const allBatches = await api.getRequest(url);
    return allBatches.data;
  };

  const getOne = async (batchId: string): Promise<BatchInfo[]> => {
    const url = entityUrl(batchId);
    const result = await api.getRequest(url);
    return result.data;
  };

  const create = async (
    period: useCreateBatchBankSlipMailingRequest,
    archive: File
  ) => {
    const url = resourceUrl;

    const { data: batch }: AxiosResponse<BatchResponse> = await api.postRequest(
      url,
      period
    );

    const { id, uploadUrl, uploadKey } = batch;

    await axios.put(uploadUrl, archive, {
      headers: { 'Content-Type': archive.type },
    });

    await api.putRequest(entityUrl(id), {
      documentId: uploadKey,
      originalFilename: uploadKey,
    });

    return batch;
  };

  const dowloadPdf = async (fileName: string) => {
    const url = `${BANK_SLIP.GET_BATCHES_DOWNLOAD_PDF(fileName)}`;
    const result = await api.getRequest(url);
    return result;
  };

  return { list, getOne, create, dowloadPdf };
};
