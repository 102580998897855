import { useMutation } from 'react-query';
import { useCreateBatchBankSlipMailingRequest } from 'types/PaymentSlip';

import {
  batchBankSlipMailing,
  BatchBankSlipMailingType,
} from 'services/batchBankSlipMailing';

export const useCreateBatchBankSlipMailing = (
  context: BatchBankSlipMailingType
) =>
  useMutation(
    ['save-client-2fa'],
    ({
      batch,
      archive,
    }: {
      batch: useCreateBatchBankSlipMailingRequest;
      archive: File;
    }) => batchBankSlipMailing(context).create(batch, archive)
  );
